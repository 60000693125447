<template>
  <ul class="directory-company-list__wrapper">
    <li
      v-for="company in companies"
      :key="company.Id"
      class="directory-company-list__list-item"
    >
      <div v-if="company" class="directory-company-list__logo">
        <a-image
          :alt="company.Name"
          :src="company.LogoUrl"
          :url="generateCompanyUrl(company)"
          :aspect-ratio="$options.consts.aspectRatios.noRatio"
          :container-aspect-ratio="
            $options.consts.aspectRatios.fmDirectoryCompanyLogo
          "
          class="directory-company-list__image"
        />
      </div>
      <div
        v-else
        class="directory-company-list__logo"
        :style="emptyCompanyStyles"
      ></div>
    </li>
    <li key="button">
      <a-button
        :href="$env.FM_DIR_DOMAIN_URL"
        class="directory-company-list__view-full-list"
        >View Full List</a-button
      >
    </li>
  </ul>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import { aspectRatios } from 'enums/aspectRatios'

export default {
  name: 'CompanyList',
  mixins: [serverCacheKey, mixins.urlGenerators],
  props: {
    companies: propValidator([PROP_TYPES.ARRAY])
  },
  consts: { aspectRatios },
  data() {
    return {
      emptyCompanyStyles: this.$helper.generateAspectRatioStyle(
        this.$options.consts.aspectRatios.fmDirectoryCompanyLogo
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/local/mixins/grid';

.directory-company-list__wrapper {
  $grid-setup: (
    $columnSetup: (
      $above-desktop-xl: repeat(3, minmax(0, 100%)),
      $tablet: repeat(5, minmax(0, 100%)),
      $mobile: repeat(2, minmax(0, 100%))
    ),
    $rowSetup: (
      $above-desktop-xl: repeat(4, minmax(0, auto)),
      $mobile: repeat(5, minmax(0, auto))
    ),
    $columnGapSetup: (
      $above-desktop-xl: 20px,
      $desktop-xl: 18px,
      $tablet: 23px
    ),
    $rowGapSetup: (
      $above-desktop-xl: 15px,
      $desktop-xl: 10px
    ),
    $combinedCellsSetup: (
      11: (
        $above-desktop-xl: #{4} / #{2} / #{5} / #{4},
        $tablet: #{3} / #{1} / #{4} / #{6},
        $mobile: #{6} / #{1} / #{7} / #{3}
      )
    )
  );
  @include gridBuilder($grid-setup);

  .directory-company-list__logo {
    border: solid 1px #707070;
  }

  .directory-company-list__image {
    @include mobile {
      width: 100%;
      overflow: hidden;
    }
  }

  .directory-company-list__view-full-list {
    $font-size: (
      $above-desktop-xl: 22px,
      $desktop-md: 13px,
      $tablet: 16px,
      $mobile: 14px
    );

    width: 100%;
    height: 100%;
    font-weight: 400;

    @include setStyleByBreakpoint('font-size', $font-size);

    @include tablet {
      width: 244px;
      height: 40px;
      margin: 10px auto 0;
    }

    @include mobile {
      text-transform: uppercase;
      width: 280px;
      max-width: 100%;
    }
  }
}
</style>
